import { template as template_e62084d41f2a49e3b9e9f22ee691d877 } from "@ember/template-compiler";
const WelcomeHeader = template_e62084d41f2a49e3b9e9f22ee691d877(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
