import { template as template_fd67b2532aee4eb0b1183d4ac8af4b08 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fd67b2532aee4eb0b1183d4ac8af4b08(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
